<template>
    <div class="forget-pwd">
        <a-steps :current="curStep">
            <a-step title="手机验证"></a-step>
            <a-step title="更改密码"></a-step>
            <a-step title="完成"></a-step>
        </a-steps>
        <div class="steps-content">
            <div class="back" v-if="curStep !== 2" @click="$emit('back')">
                <span class="iconfont icon-fanhui"></span>
                返回登录
            </div>
            <a-form ref="formRef1" :rules="rules" @finish="_handleFinish" :model="formState1">
                <a-form-item v-if="curStep === 0" ref="phone" has-feedback label="手机号：" name="phone">
                    <a-input placeholder="请输入手机号" v-model:value="formState1.phone"></a-input>
                </a-form-item>
                <a-form-item v-if="curStep === 0" ref="code" name="code" label="验证码：" class="code">
                    <a-input id="code" placeholder="请输入验证码" v-model:value="formState1.code" />
                    <a-button @click="_getCode" :disabled="disabled">{{ btnTxt }}</a-button>
                </a-form-item>
                <a-form-item v-if="curStep === 1" ref="newPwd" name="newPwd" label="新密码：">
                    <a-input-password placeholder="请输入新密码" v-model:value="formState1.newPwd" />
                </a-form-item>
                <a-form-item v-if="curStep === 1" ref="okPwd" name="okPwd" label="确认密码：">
                    <a-input-password placeholder="请再次输入密码" v-model:value="formState1.okPwd" />
                </a-form-item>
                <a-form-item>
                    <a-button v-if="curStep === 0" html-type="submit" class="next">下一步</a-button>
                    <a-button v-if="curStep === 1" class="last" @click="curStep = 0">上一步</a-button>
                    <a-button v-if="curStep === 1" html-type="submit" class="ok">提交</a-button>
                </a-form-item>
            </a-form>
            <div v-if="curStep === 2" class="success">
                <img src="@/assets/images/success.png" alt="" />
                <h2>密码更改成功！</h2>
                <p>本页面{{ second }}秒后将自动跳转到工作台，如果没有跳转请点击 <span @click="go">跳转</span></p>
            </div>
        </div>
    </div>
</template>
<script lang="ts">
import { message } from 'ant-design-vue';
import { Vue, Options } from 'vue-class-component';
import { getLoginType, getRedirectUrl } from '../../utils';
import { RuleObject } from 'ant-design-vue/es/form/interface';
import { setToken } from '../../service/token_util';
import { JeecgAuthService, IJeecgPhoneLoginParams, CookieStorage, LoginUser } from '@do/do-auth-web-client';
import { AuthService } from '../../service/auth_service';

@Options({
    watch: {
        curStep() {
            if (this.curStep === 2) {
                this.intervalId = setInterval(async () => {
                    this.second--;
                    if (this.second <= 0) {
                        clearInterval(this.intervalId);
                        window.location.href = await getRedirectUrl();
                    } else {
                        this.disabled = true;
                    }
                }, 1000);
            }
        },
    },
})
export default class Tryout extends Vue {
    curStep = 0;

    formState1 = {
        phone: '',
        code: '',
        newPwd: '',
        okPwd: '',
    };

    token = '';

    rules = {
        phone: [
            { required: true, message: '请输入手机号', trigger: ['change', 'blur'] },
            { pattern: /^1[0-9]{10}$/, message: '请输入正确格式', trigger: ['change', 'blur'] },
        ],
        code: [{ required: true, message: '请输入验证码', trigger: ['change', 'blur'] }],
        newPwd: [{ required: true, message: '请输入新密码', trigger: ['change', 'blur'] }],
        okPwd: [
            { required: true, message: '请再次输入密码', trigger: ['change', 'blur'] },
            {
                validator: async (rule: RuleObject, value: string) => {
                    if (value !== this.formState1.newPwd) {
                        return Promise.reject('两次输入密码不一致');
                    } else {
                        return Promise.resolve();
                    }
                },
                trigger: ['change', 'blur'],
            },
        ],
    };

    btnTxt = '获取验证码';

    time = 60;

    disabled = false;

    intervalId!: any;

    cookieStorage: CookieStorage = new CookieStorage();

    second = 5;

    mounted() {}

    async go() {
        window.location.href = await getRedirectUrl();
    }

    private async _getCode(): Promise<void> {
        await (this.$refs.formRef1 as ANY).validate('phone');
        const { phone } = this.formState1;
        this._countDown();
        const key = 'updatable';
        message.loading({ content: '短信发送中', key, duration: 0 });
        try {
            const data = await JeecgAuthService.sms({
                mobile: phone,
                smsmode: '0',
            });

            message.success({ content: '验证码已发送', key });
        } catch (ex: any) {
            message.error({ content: '短信发送失败: ' + ex.message, key });
            clearInterval(this.intervalId);
            this.btnTxt = '获取验证码';
            this.disabled = false;
        } finally {
        }
    }

    private _countDown() {
        this.btnTxt = `${this.time}s`;
        this.disabled = true;
        this.intervalId = setInterval(() => {
            this.time--;
            this.btnTxt = `${this.time}s`;
            if (this.time <= 0) {
                clearInterval(this.intervalId);
                this.btnTxt = '获取验证码';
                this.disabled = false;
                if (this.time === 0) {
                    this.time = 60;
                }
            } else {
                this.disabled = true;
            }
        }, 1000);
    }

    private async _handleFinish(): Promise<void> {
        if (this.curStep === 0) {
            try {
                const params: IJeecgPhoneLoginParams = {
                    mobile: this.formState1.phone,
                    captcha: this.formState1.code,
                    remember_me: true,
                    from: getLoginType(),
                };
                const loginResult = await JeecgAuthService.phoneLogin(params);
                this._processLogin(loginResult);
            } catch (error: ANY) {
                console.error(error);
                message.error(error.message, 3);
            }
        } else if (this.curStep === 1) {
            const data = await AuthService.forgetPwd({
                password: this.formState1.okPwd,
            });
            message.success('修改成功');
            this.curStep = 2;
        }
    }

    private async _processLogin(loginResult: any): Promise<void> {
        const { token, userInfo } = loginResult;

        const user: LoginUser = {
            token,
            user: {
                id: userInfo.id,
                avatar: userInfo.avatar,
                realname: userInfo.realname,
                username: userInfo.username,
            },
        };
        this.cookieStorage.setUserInfo(user);
        this.token = token;
        setToken(token);
        this.curStep = 1;
    }
}
</script>
<style lang="less">
@import './index.less';
</style>
