<template>
    <div class="register-container">
        <div class="content">
            <div class="banner">
                <div class="title">
                    <div class="main-title">欢迎来到</div>
                    <div class="sub-title">进入闪设，开启智能设计</div>
                </div>
                <div class="banner-app-name">闪设</div>
                <img src="../../assets/images/trial_modal_pic.png" alt="" />
            </div>
            <a-form :model="formData" name="sb" @finish="submitForm" layout="vertical">
                <a-form-item
                    label="用户名"
                    name="userName"
                    :rules="[{ required: true, message: '请输入10位以内字符', pattern: '^.{0,10}$' }]"
                >
                    <a-input v-model:value="formData.userName" placeholder="请输入用户名" />
                </a-form-item>

                <a-form-item
                    label="手机号"
                    name="phone"
                    :rules="[{ required: true, message: '请输入正确的手机号', pattern: /^1[3456789]\d{9}$/ }]"
                >
                    <a-input v-model:value="formData.phone" placeholder="请输入手机号" />
                </a-form-item>

                <a-form-item label="邮箱" name="email" :rules="[{ required: true, message: '请输入正确的邮箱', type: 'email' }]">
                    <a-input v-model:value="formData.email" placeholder="请输入邮箱" />
                </a-form-item>

                <a-form-item label="业务类型" name="industry">
                    <a-radio-group v-model:value="formData.industry" style="display: flex; justify-content: space-between">
                        <a-radio-button v-for="(item, index) in industries" :key="index" :value="item">{{ item.name }}</a-radio-button>
                    </a-radio-group>
                </a-form-item>

                <a-form-item style="text-align: center; margin-top: 68px">
                    <a-button class="register-btn" type="primary" html-type="submit">立即注册</a-button>
                </a-form-item>
                <div class="bottom-text">注册成功后，请使用短信验证登录</div>
            </a-form>
        </div>
    </div>
</template>

<script setup lang="ts">
import { onMounted, reactive, ref } from 'vue';
import { EnvConfig } from '@/env';
import { message } from 'ant-design-vue';
import { useRoute, useRouter } from 'vue-router';

interface IFormData {
    userName: string;
    phone: string;
    email: string;
    industry: {
        id: string;
        childId: string;
    };
}

const formData = reactive<IFormData>({
    userName: '',
    phone: '',
    email: '',
    industry: {
        id: '',
        childId: '',
    },
});

const router = useRouter();

const bVisible = ref(true);
const industries = ref<{ id: string; name: string; childId: string }[]>([]);
onMounted(async () => {
    fetch(`${EnvConfig.getBaseUrl()}/doMistServer/communityUser/findByCondition`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            pid: '1250472583391621120',
            includeAllChildren: true,
            auth: 1,
            deep: 5,
            sortVo: { orderBy: 'sort', sort: 'asc' },
        }),
    }).then((res) => {
        if (res.status === 200) {
            res.json().then((data) => {
                industries.value = data.result.map((item: any) => ({
                    id: item.id,
                    childId: item.children?.[0].id,
                    name: item.name,
                }));
                formData.industry = industries.value[0];
            });
        }
    });
});
const submitForm = async (values: IFormData) => {
    await fetch(`${EnvConfig.getBaseUrl()}/v3/sys/user/addCommunity`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            ...values,
            industry: values.industry.id,
            workscene: values.industry.childId,
            userStatus: 4,
            selectedroles: '1810194602511429634',
            realname: values.userName,
            extendStatus: 1,
            doGold: 100,
        }),
    }).then((res) => {
        res.json().then((data) => {
            if (data.code === 200) {
                fetch(`${EnvConfig.getBaseUrl()}/doMistServer/communityUser/initUserCommunity?username=${values.userName}`).then(
                    async (res) => {
                        res.json().then((data) => {
                            if (data.code === 200) {
                                message.success('账号注册成功，即将跳转登录页面');
                                const returnUrl = encodeURIComponent(
                                    `${EnvConfig.getHomeUrl2()}/shanshe-community/index?tenantId=0&app=shanshe-community`,
                                );
                                window.location.search = `?returnUrl=${returnUrl}`;
                            } else {
                                message.error(data.message || data.msg);
                            }
                        });
                    },
                );
            } else {
                message.error(data.message || data.msg);
            }
        });
    });
};

const exitUse = () => {
    // Implement exit logic here
};
</script>

<style lang="less" src="./register.less"></style>
