/*
 * @Author: your name
 * @Date: 2021-11-12 09:33:17
 * @LastEditTime: 2024-07-11 16:44:06
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \do-auth-web\src\utils.ts
 */
// export const saveToken = (token: string, exdays: number) => {
//     const exdate = new Date();
//     exdate.setTime(exdate.getTime() + 24 * 60 * 60 * 1000 * exdays);
//     window.document.cookie = `${window.ENV_CONFIG.cookieName}=${token};path=/;expires=${exdate.toUTCString()}`;
// };

import { CookieStorage, IUserManager, UserManager } from '@do/do-auth-web-client';
import { EnvConfig } from './env';

// export const getToken = () => {
//     let ret = '';
//     if (document.cookie.length > 0) {
//         const arr = document.cookie.split('; ');
//         arr.forEach((item) => {
//             const arr2 = item.split('=');
//             if (arr2[0] === window.ENV_CONFIG.cookieName) {
//                 // eslint-disable-next-line prefer-destructuring
//                 ret = arr2[1];
//             }
//         });
//     }
//     return ret;
// };

// export const clearCookie = () => {
//     saveToken('', -1);
// };

export const getRedirectUrl = async () => {
    const cookie_storage = new CookieStorage();
    const token = encodeURIComponent(cookie_storage.getUserInfoString()!);
    let url = decodeURIComponent(getQueryStringByName('returnUrl'));
    if (!url) {
        return `${EnvConfig.getHomeUrl()}?token=${token}`;
    }
    const split = url.indexOf('?') !== -1 ? '&' : '?';
    url = `${url}${split}token=${token}`;
    return url;
};

export const getLoginType = () => {
    // 获取整个查询字符串（包括参数）
    const queryString = window.location.search;
    const params = new URLSearchParams(queryString);
    const type = params.get('type'); //
    if (type === 'desktop') return '1'; // 客户端
    return type ?? '0';
};

export const getQueryStringByName = (name: string): string => {
    const result = window.location.search.match(new RegExp(`[\\?\\&]${name}=([^\\&]+)`, 'i'));

    if (result === null || result.length < 1) {
        return EnvConfig.getHomeUrl();
    }
    return result[1];
};

const userManager: IUserManager = new UserManager();

export const checkIfLogin = async () => {
    const userManager: IUserManager = new UserManager();
    userManager.init({
        env: EnvConfig.getEnv(),
        loginUrl: '',
        baseUrl: EnvConfig.getBaseUrl(),
    });

    userManager.registerOnLoginCallback(async (user) => {
        const returnUrl = await getRedirectUrl();
        window.location.href = returnUrl;
    });

    const user = await userManager.checkLogin();
    return !!user;
};
