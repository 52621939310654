<!--
 * @Author: your name
 * @Date: 2021-08-23 10:19:46
 * @LastEditTime: 2024-07-11 17:31:26
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \do-auth-web\src\app.vue
-->
<style lang="less" src="./app.less"></style>
<template>
    <header>
        <section class="content-common-width">
            <div class="logo-wrapper">
                <img src="./assets/images/logo.png" width="143" height="39" />
            </div>
        </section>
    </header>
    <main>
        <RouterView />
    </main>
</template>
