<template>
    <a-modal class="application-exp" width="620px" centered :footer="null" :visible="visible" @cancel="$emit('close')">
        <template #title>
            <h3>申请试用</h3>
            <p>如需申请试用，请填写表单信息，我们将在第一时间与您联系</p>
        </template>
        <a-form ref="formRef" :model="formState" :rules="rules" @finish="_handleFinish">
            <a-form-item ref="companyName" has-feedback label="组织名称" name="companyName">
                <a-input placeholder="请输入组织名称" v-model:value="formState.companyName" />
            </a-form-item>
            <a-form-item ref="userName" has-feedback label="姓名" name="userName">
                <a-input placeholder="请输入姓名" v-model:value="formState.userName" />
            </a-form-item>
            <a-form-item ref="phone" has-feedback label="手机号" name="phone">
                <a-input placeholder="请输入手机号码" v-model:value="formState.phone" />
            </a-form-item>
            <a-form-item ref="email" has-feedback label="邮箱" name="email">
                <a-input placeholder="请输入邮箱" v-model:value="formState.email" />
            </a-form-item>
            <a-form-item ref="description" has-feedback label="需求简述" name="description">
                <a-textarea placeholder="请简单描述您的需求、用途等" v-model:value="formState.description"></a-textarea>
            </a-form-item>
            <a-form-item>
                <a-button html-type="submit" class="ok">提交申请</a-button>
            </a-form-item>
        </a-form>
    </a-modal>
</template>
<script lang="ts">
import { message } from 'ant-design-vue';
import axios from 'axios';
import { Options, Vue } from 'vue-class-component';

@Options({
    props: ['visible'],
})
export default class Tryout extends Vue {
    formState = {
        companyName: '',
        userName: '',
        phone: '',
        email: '',
        description: '',
    };

    rules = {
        companyName: [
            { required: true, message: '请输入企业名称', trigger: ['change', 'blur'] },
            { pattern: /^[\u4e00-\u9fa5_ a-zA-Z0-9]+$/, message: '请输入正确格式', trigger: ['change', 'blur'] },
        ],
        userName: [
            { required: true, message: '请输入姓名', trigger: ['change', 'blur'] },
            { pattern: /^[\u4e00-\u9fa5_ a-zA-Z0-9]+$/, message: '请输入正确格式', trigger: ['change', 'blur'] },
        ],
        phone: [
            { required: true, message: '请输入手机号', trigger: ['change', 'blur'] },
            { pattern: /^1[0-9]{10}$/, message: '请输入正确格式', trigger: ['change', 'blur'] },
        ],
        email: [
            { required: true, message: '请输入邮箱', trigger: ['change', 'blur'] },
            {
                pattern: /^([a-zA-Z0-9._-])+@([a-zA-Z0-9_-])+((.[a-zA-Z0-9_-]{2,3}){1,2})$/,
                message: '请输入正确格式',
                trigger: ['change', 'blur'],
            },
        ],
        description: [{ required: true, message: '请简单描述您的需求、用途等', trigger: ['change', 'blur'] }],
    };

    private async _handleFinish(values: KV) {
        const {
            data: { code, msg },
        } = (await axios.post('https://api.designorder.cn/designBackend/doOperationBackend/collectUsers/saveUserInfo', values, {
            headers: { 'content-type': 'application/json;charset=UTF-8' },
        })) as ANY;
        if (code === 200) {
            message.success(msg);
            this.formState = {
                companyName: '',
                userName: '',
                phone: '',
                email: '',
                description: '',
            };
            (this.$refs.formRef as KV).clearValidate();
            this.$emit('close');
        }
    }
}
</script>
<style lang="less">
@import './index.less';
</style>
